import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import PrivacyPolicy from "./pages/privacy_policy";
import CancellationPolicy from "./pages/cancellation";
import Deleted from "./pages/deleted";
import AccountDeletion from "./pages/account_deletion";
import Terms from "./pages/terms";
import Login from "./pages/login/login";
import Otp from "./pages/otp/otp";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/login" element={<Login />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/privacy" element={<PrivacyPolicy/>}/>
        <Route path="/cancellation" element={<CancellationPolicy/>}/>
        <Route path="/terms" element={<Terms/>}/>
        <Route path="/account-deletion" element={<AccountDeletion/>}/>
        <Route path="/deleted" element={<Deleted/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
