import "./../login/login.css";
import "./otp.css";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card } from "react-bootstrap/";
import { TextField, Button } from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import axios from "../../network/api";
import { baseUrl } from "../../network/constants/constants";
import AppConstants from "../../components/AppConstants";
import config from '../../network/auth';

function Otp() {
  const location = useLocation();
  const email = location.state?.data.email;
  const [otp, setOTP] = useState("");
  const [loading, setLoading] = useState(false);

  const [otpError, setOtpError] = useState(false);
  const navigate = useNavigate();
  const handleOTP = (event) => {
    setOTP(event.target.value);
  };

  const validateOTP = (otp) => {
    const regex = /^\d{6}$/;
    setOtpError(!regex.test(otp));
    return regex.test(otp);
  };

  const PostOTP = (props) => {
    setLoading(true);

    const payload = {
      email: email,
      otp: otp
    };

    console.log("payload: " + JSON.stringify(payload));

    axios
      .post(baseUrl + "auth/validate/", payload)
      .then((response) => {
        setLoading(false);
        localStorage.setItem(AppConstants.JWT,response.data["token"]);
        callDeleteAPI();
        // navigate("../home", { replace: true });
      })
      .catch((error) => {
        console.log(error + "errrorrriiii");
        setLoading(true);
      });
  };


  const callDeleteAPI = (props) => {
    setLoading(true);
    axios
      .get(baseUrl + "auth/delete-account/",config)
      .then((response) => {
        setLoading(false);
        localStorage.setItem(AppConstants.JWT,response.data["token"]);
        navigate("../deleted", { replace: true });
      })
      .catch((error) => {
        console.log(error + "errrorrriiii");
        setLoading(true);
      });
  };

  return (
    <section id="login">
      <div className="content">
        <div className="brand-container">
          <div className="business-name">Onescribe</div>
        </div>
        <Card className="login-card card mt-5 rounded-4">
          <div className="login-title">OTP Verification</div>

          <TextField
            id="otp"
            className="mt-4"
            label="Enter OTP"
            variant="outlined"
            size="small"
            type="number"
            value={otp}
            onChange={handleOTP}
            error={otpError}
            helperText={otpError ? "Invalid OTP Number" : ""}
          />

          <Button
            variant="contained"
            className="login-btn mt-5 mb-3"
            onClick={() => {
              if (validateOTP(otp)) {
                setLoading(true);
                PostOTP();
              }
            }}
          >
            Submit
          </Button>
          <div className="not-number">
            {email}
            <div onClick={()=>navigate("../login", {replace: true})}>Not your Email? Click to Go Back </div>
          </div>

          <TailSpin
            className="mt-5"
            height="30"
            width="30"
            radius="1"
            color="#796dec"
            ariaLabel="loading"
            visible={loading}
          />
        </Card>
      </div>

    
    </section>
  );
}

export default Otp;

