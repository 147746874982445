import React from 'react'
import NavbarComponent from '../components/navbar/navbar'
import SuccessComponent from '../components/success/success'
import ServiceComponent from '../components/services/services'
import DownloadComponent from '../components/download/download'
import FooterComponent from '../components/footer/footer'

const Home = () => {
  return (
   <section id='home'>
      <NavbarComponent/>
      <SuccessComponent/>
      <ServiceComponent/>
      <DownloadComponent/>
      <FooterComponent/>
   </section>
  )
}
export default Home
