import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


function AccountDeletion(){
  const navigate = useNavigate();
  const goToLogin=()=>{
    navigate("../login",{replace: true})
  }
  return (
    <section style={{ textAlign: "start" }} id="privacypolicy">
      <Container>
        <h1 className="heading my-5" style={{ textAlign: "center" }}>
          Account Deletion
        </h1>
        <p className="para">
          Before you proceed with the deletion of your account from the IQwin
          application, please be aware that this action is irreversible. Once
          your account is deleted, you will not be able to recover any details,
          including your payment history and any other related data associated
          with the IQwin mobile app. This is a crucial step to ensure the
          security and privacy of your personal information. If you are certain
          about this decision, please follow the instructions provided to
          complete the account deletion process.
        </p>
        <div className="submit-div d-flex justify-content-center mt-5">
          <button onClick={()=> goToLogin()} type="submit" className="btn btn-danger submit-btn">
            Delete Account Request
          </button>
        </div>
      </Container>
    </section>
  );
};
export default AccountDeletion;
